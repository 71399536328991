import React from 'react';
import Avatar, { AvatarStyle } from 'avataaars';
import avatarHelper from '../../helpers/avatarHelper';
import { UserType } from '../../components/Room/types';

interface Props {
    user?: UserType;
    className?: string;
    style?: React.CSSProperties;
    avatarStyle?: number[];
}

const UserAvatar = ({ user, className, style, avatarStyle }: Props) => {
    const avatar = avatarStyle || user?.avatar

    if (avatar == null) {
        return null;
    }

    return (
        <Avatar
            className={className}
            avatarStyle={AvatarStyle.Transparent}
            style={style}
            accessoriesType={
                avatarHelper.accessoriesType[
                    avatar[avatarHelper.typeIndex.accessories]
                ]
            }
            topType={
                avatarHelper.topType[avatar[avatarHelper.typeIndex.top]]
            }
            eyebrowType={
                avatarHelper.eyebrowType[
                    avatar[avatarHelper.typeIndex.eyeBrow]
                ]
            }
            eyeType={
                avatarHelper.eyeType[avatar[avatarHelper.typeIndex.eye]]
            }
            mouthType={
                avatarHelper.mouthType[
                    avatar[avatarHelper.typeIndex.mouth]
                ]
            }
            clotheType={
                avatarHelper.clotheType[
                    avatar[avatarHelper.typeIndex.clothe]
                ]
            }
            facialHairType={
                avatarHelper.facialHairType[
                    avatar[avatarHelper.typeIndex.facialHair]
                ]
            }
            hairColor={
                avatarHelper.hairColor[
                    avatar[avatarHelper.typeIndex.hairColor]
                ]
            }
            clotheColor={
                avatarHelper.clotheColor[
                    avatar[avatarHelper.typeIndex.clotheColor]
                ]
            }
            facialHairColor={
                avatarHelper.facialHairColor[
                    avatar[avatarHelper.typeIndex.facialHairColor]
                ]
            }
            skinColor={
                avatarHelper.skinColor[
                    avatar[avatarHelper.typeIndex.skinColor]
                ]
            }
            hatColor={
                avatarHelper.hatColor[
                    avatar[avatarHelper.typeIndex.hatColor]
                ]
            }
            graphicType={
                avatarHelper.graphicType[
                    avatar[avatarHelper.typeIndex.graphic]
                ]
            }
        />
    );
};

export default React.memo(UserAvatar);
