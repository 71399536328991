import axios from 'axios';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import React from 'react';
import CustomizeAvatar from '../CustomizeAvatar';
import UserAvatar from '../UserAvatar';
import { AuthUserState, setName } from '../../store/reducers/userSlice';
import config from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import * as styles from './index.module.scss';

interface Props {
    isCustomizeAvatarEnabled: boolean;
    setIsCustomizeAvatarEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    // avatarStyle: number[];
}

const UserCustomize = ({
    isCustomizeAvatarEnabled,
    setIsCustomizeAvatarEnabled,
}: Props) => {
    const dispatch = useAppDispatch();

    const refInputUsername = React.useRef<HTMLInputElement>();
    const user = useAppSelector((state) => state.user);

    const onUsernameChange = (e) => {
        let nameChanged = e.target.value.replace(
            /[^á!ÁéÉóÓðÐþÞýÝæÆa-zA-Z0-9- ]/g,
            ''
        );
        nameChanged = nameChanged.split('  ').join(' ');
        dispatch(setName(nameChanged));
    };

    React.useEffect(() => {
        if (refInputUsername.current) {
            refInputUsername.current.focus();
        }
    }, [isCustomizeAvatarEnabled]);

    if (isCustomizeAvatarEnabled) {
        return (
            <CustomizeAvatar
                setIsCustomizeAvatarEnabled={setIsCustomizeAvatarEnabled}
            />
        );
    }

    return (
        <div className={styles.enterNameContainer}>
            <input
                ref={refInputUsername}
                type='text'
                maxLength={32}
                minLength={2}
                value={user.name || user.username}
                onChange={onUsernameChange}
                placeholder='Enter a name'
                className='input'
            />

            <div className={styles.avatarContainer}>
                <UserAvatar
                    avatarStyle={user.avatar}
                    className={styles.avatar}
                />
                <div className={styles.editContainer}>
                    <button
                        type='button'
                        className={styles.btnCustomizeAvatar}
                        onClick={() => setIsCustomizeAvatarEnabled(true)}
                    >
                        Customize
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserCustomize;
