import React from 'react';
import avatarHelper, { getRandomAvatar } from '../../helpers/avatarHelper';
import AvatarTypeSelector from './AvatarTypeSelector';
import UserAvatar from '../UserAvatar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import axios from 'axios';
import config from '../../config';
import * as styles from './index.module.scss';
import { setUserAvatar } from '../../store/reducers/userSlice';
import Button from '../../components/Button';

interface Props {
    avatar: number[];
    setIsCustomizeAvatarEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomizeAvatar = ({
    // avatarStyle,
    // setAvatarStyle,
    setIsCustomizeAvatarEnabled,
}) => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [avatar, setAvatar] = React.useState<number[]>([...user.avatar]);
    const [isSaving, setIsSaving] = React.useState(false);
    const onSaveAvatar = async (e) => {
        e.preventDefault();
        // localStorage.setItem('avatar', JSON.stringify(avatar));
        // setAvatar(avatar);
        dispatch(setUserAvatar(avatar));

        if (user.isSignedIn) {
            const isLocal = window.location.hostname === 'localhost';
            setIsSaving(true);
            try {
                await axios.post(
                    (isLocal ? config.userApiLocal : config.userApiProd) +
                        '/api/settings',
                    {
                        avatar: avatar,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + user.accessToken,
                        },
                    }
                );
            } catch (error) {}
            setIsSaving(false);
        }
        setIsCustomizeAvatarEnabled(false);
    };

    const onValueChange = (e, typeIndex) => {
        const updatedAvatar = [...avatar];

        updatedAvatar[typeIndex] = parseInt(e.target.value);

        setAvatar(updatedAvatar);
    };

    const onCancel = (e) => {
        e.preventDefault();
        setIsCustomizeAvatarEnabled(false);
    };

    const randomAvatar = () => {
        setAvatar(getRandomAvatar());
    };

    return (
        <>
            <div className={styles.randomContainer}>
                <Button
                    type='button'
                    size='medium'
                    color='transparent'
                    title='Randomize'
                    onClick={() => randomAvatar()}
                >
                    🎲
                </Button>
            </div>
            <div className={styles.avatarContainer}>
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.top}
                    types={avatarHelper.topType}
                    type={avatar[avatarHelper.typeIndex.top]}
                    setAvatarStyle={setAvatar}
                    title='Top'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.eyeBrow}
                    types={avatarHelper.eyebrowType}
                    // type={avatarHelper.typeIndex.eyeBrow}
                    type={avatar[avatarHelper.typeIndex.eyeBrow]}
                    setAvatarStyle={setAvatar}
                    title='Eye brows'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.eye}
                    types={avatarHelper.eyeType}
                    type={avatar[avatarHelper.typeIndex.eye]}
                    setAvatarStyle={setAvatar}
                    title='Eyes'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.accessories}
                    types={avatarHelper.accessoriesType}
                    type={avatar[avatarHelper.typeIndex.accessories]}
                    setAvatarStyle={setAvatar}
                    title='Glasses'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.mouth}
                    types={avatarHelper.mouthType}
                    type={avatar[avatarHelper.typeIndex.mouth]}
                    setAvatarStyle={setAvatar}
                    title='Mouth'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.facialHair}
                    types={avatarHelper.facialHairType}
                    type={avatar[avatarHelper.typeIndex.facialHair]}
                    setAvatarStyle={setAvatar}
                    title='Facial hair'
                />
                <AvatarTypeSelector
                    className={styles.avatarSelector}
                    avatarStyle={avatar}
                    typeIndex={avatarHelper.typeIndex.clothe}
                    types={avatarHelper.clotheType}
                    type={avatar[avatarHelper.typeIndex.clothe]}
                    setAvatarStyle={setAvatar}
                    title='Clothes'
                />
                {avatar[avatarHelper.typeIndex.clothe] === 3 && (
                    <AvatarTypeSelector
                        className={styles.avatarSelector}
                        avatarStyle={avatar}
                        typeIndex={avatarHelper.typeIndex.graphic}
                        types={avatarHelper.graphicType}
                        type={avatar[avatarHelper.typeIndex.graphic]}
                        setAvatarStyle={setAvatar}
                        title='Graphic'
                    />
                )}
                {avatar[avatarHelper.typeIndex.top] > 7 &&
                    avatar[avatarHelper.typeIndex.top] !== 18 && (
                        <div>
                            <label htmlFor='hairColor'>Hair color</label>
                            {` `}
                            <select
                                id='hairColor'
                                value={avatar[avatarHelper.typeIndex.hairColor]}
                                onChange={(e) =>
                                    onValueChange(
                                        e,
                                        avatarHelper.typeIndex.hairColor
                                    )
                                }
                            >
                                {avatarHelper.hairColor.map((c, index) => (
                                    <option key={c} value={index}>
                                        {c}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                {avatar[avatarHelper.typeIndex.top] >= 2 &&
                    avatar[avatarHelper.typeIndex.top] <= 7 && (
                        <div>
                            <label htmlFor='hatColor'>Hat color</label>
                            {` `}
                            <select
                                id='hatColor'
                                value={avatar[avatarHelper.typeIndex.hatColor]}
                                onChange={(e) =>
                                    onValueChange(
                                        e,
                                        avatarHelper.typeIndex.hatColor
                                    )
                                }
                            >
                                {avatarHelper.hatColor.map((c, index) => (
                                    <option key={c} value={index}>
                                        {c}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                {avatar[avatarHelper.typeIndex.facialHair] !== 0 && (
                    <div>
                        <label htmlFor='facialHairColor'>
                            Facial hair color
                        </label>
                        {` `}
                        <select
                            id='facialHairColor'
                            value={
                                avatar[avatarHelper.typeIndex.facialHairColor]
                            }
                            onChange={(e) =>
                                onValueChange(
                                    e,
                                    avatarHelper.typeIndex.facialHairColor
                                )
                            }
                        >
                            {avatarHelper.facialHairColor.map((c, index) => (
                                <option key={c} value={index}>
                                    {c}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div>
                    <label htmlFor='skinColor'>Skin color</label>
                    {` `}
                    <select
                        id='skinColor'
                        value={avatar[avatarHelper.typeIndex.skinColor]}
                        onChange={(e) =>
                            onValueChange(e, avatarHelper.typeIndex.skinColor)
                        }
                    >
                        {avatarHelper.skinColor.map((c, index) => (
                            <option key={c} value={index}>
                                {c}
                            </option>
                        ))}
                    </select>
                </div>
                {avatar[avatarHelper.typeIndex.clothe] >= 2 && (
                    <div>
                        <label htmlFor='clothColor'>Clothes color</label>
                        {` `}
                        <select
                            id='clothColor'
                            value={avatar[avatarHelper.typeIndex.clotheColor]}
                            onChange={(e) =>
                                onValueChange(
                                    e,
                                    avatarHelper.typeIndex.clotheColor
                                )
                            }
                        >
                            {avatarHelper.clotheColor.map((c, index) => (
                                <option key={c} value={index}>
                                    {c}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <UserAvatar avatarStyle={avatar} className={styles.avatar} />

                <div style={{ marginTop: '1rem' }}>
                    <Button
                        type='button'
                        size='medium'
                        color='primary'
                        onClick={onSaveAvatar}
                        disabled={isSaving}
                    >
                        Save
                    </Button>
                    <Button
                        type='button'
                        size='medium'
                        color='secondary'
                        onClick={onCancel}
                        style={{ marginLeft: '.8rem' }}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CustomizeAvatar;
