import classNames from 'classnames';
import { Link } from 'gatsby';
import * as styles from './index.module.scss';

type Props = {
    children: any;
    isLink?: boolean;
    to?: string;
    onClick?: (e: any) => void;
    type?: 'button' | 'submit' | 'reset';
    size: 'small' | 'medium' | 'large';
    color: 'primary' | 'secondary' | 'default' | 'transparent';
    className?: string;
    isBlock?: boolean;
    icon?: any;
    title?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
};

const Button = ({
    isLink = false,
    to,
    children,
    onClick,
    type = 'button',
    size = 'medium',
    color = 'default',
    className,
    isBlock = false,
    icon,
    title,
    disabled = false,
    style,
}: Props) => {
    if (isLink) {
        return (
            <Link
                style={style}
                className={classNames(
                    styles.button,
                    styles[size],
                    styles[color],
                    isBlock && styles.isBlock,
                    className && className,
                    disabled && styles.disabled
                )}
                to={to}
                title={title}
            >
                {children}
                {icon && icon}
            </Link>
        );
    }
    return (
        <button
            style={style}
            className={classNames(
                styles.button,
                styles[size],
                styles[color],
                isBlock && styles.isBlock,
                className && className,
                disabled && styles.disabled
            )}
            title={title}
            disabled={disabled}
            type={type}
            onClick={onClick}
        >
            {children}
            {icon && icon}
        </button>
    );
};

export default Button;
