interface Props {
    avatarStyle: number[];
    typeIndex: number;
    setAvatarStyle: React.Dispatch<React.SetStateAction<number[]>>;
    type: number;
    types: string[];
    offsetTop?: number;
    title: string;
    className?: string;
}

const AvatarTypeSelector = ({
    avatarStyle,
    typeIndex,
    setAvatarStyle,
    type,
    types,
    offsetTop,
    title,
    className,
}: Props) => {
    return (
        <div
            className={className}
        >
            <span>{title}</span>
            <div>
                <button
                    onClick={() => {
                        const updatedAvatarStyle = [...avatarStyle];
                        updatedAvatarStyle[typeIndex] =
                            type - 1 < 0 ? types.length - 1 : type - 1;
                        setAvatarStyle(updatedAvatarStyle);
                    }}
                >
                    ◀️
                </button>
                <button
                    onClick={() => {
                        const updatedAvatarStyle = [...avatarStyle];
                        updatedAvatarStyle[typeIndex] =
                            type + 1 === types.length ? 0 : type + 1;
                        setAvatarStyle(updatedAvatarStyle);
                    }}
                >
                    ▶️
                </button>
            </div>
        </div>
    );
};

export default AvatarTypeSelector;
